export const Attendance = {
  Status: {
    attended: 1, // 수업
    absent: 2, // 결석
    present: 3, // 등원
    left: 4, // 하원
    custom: 5, // 사용자 정의 상태
  },
  TeacherStatus: {
    clockIn: 11, // 교사의 출근
    clockOut: 12, // 교사의 퇴근
  },
  NotificationMethod: {
    push: 1,
    pushAndSms: 2,
    sms: 3,
  },
  AttCodeBase: {
    parentPhone: 1,
    studentPhone: 2,
  },
};
