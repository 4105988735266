import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Start from 'pages/start/Start';
import RouteListUser from './RouteListUser';
import RouteListTeacherSide from './RouteListTeacherSide';
import RouteListTSBill from './RouterListTSBill';
import RouteListTSManagement from './RouterListTSManagement';

import RouteListEtc from './RouteListEtc';
import RouteListTSPost from './RouteListTSPost';
import LoadingScreen from 'presenters/common/LoadingScreen';

function RouteList() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="/" element={<Start />} />

        {/* 공통 화면 시작 */}
        {RouteListUser}

        {/* 학생측 화면 시작 */}
        {/* {RouteListStudentSide} */}

        {/* 학원관리 - /teacherSide/tool/ */}
        {RouteListTSManagement}

        {/* 글관리 - /teacherSide/post/ */}
        {RouteListTSPost}

        {/* 학원비 - /teacherSide/bill/ */}
        {RouteListTSBill}

        {/* 교사측 기타 화면 */}
        {RouteListTeacherSide}

        {RouteListEtc}

        <Route
          element={
            <div>
              <h2>이 페이지는 존재하지 않습니다.</h2>
              {location.pathname}
            </div>
          }
        />
      </Routes>
    </Suspense>
  );
}
export default RouteList;
