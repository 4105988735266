import Str from 'utils/Str';
import Base64 from 'utils/Base64';
import generalInterface from './generalInterface';
import { getAccessToken } from './helper.storage';
import axios from 'axios';
import ImageHelper from 'utils/ImageHelper';
import { ServiceConfig } from 'constants/shared/ServiceConfig';
import Config from 'config/Config';
import interfaceBridge from 'utils/interfaceBridge';

console.log('helper.uploader.js loaded');

const EVENT_TYPE_UPLOAD_SINGLE = 'upload_single';

const STARTED = 'started';
const UPLOADING = 'uploading';
const COMPLETE = 'complete';
// const ERROR = 'error';

const SINGLE_IMAGE_FIELD = 'myImage';
const SINGLE_ATTACHMENT_FIELD = 'myAttachment';
const SINGLE_TEMPFILE_FIELD = 'myTempFile';

const dataURItoBlob = (dataURI: string) => {
  const bytes =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

const sendNativeEvent = (eventType, eventJsonStr) => {
  const encodedEvent = Base64.encode(eventJsonStr);
  generalInterface.onNativeEvent(eventType, encodedEvent);
};

const uploadFile = async (
  academyId: number,
  postIdentifier: string,
  kind: 'postImage' | 'postAttachment' | 'tempImage',
  fileObj: File,
  eventIdentifier: string,
  tempFileType?: string, // temp 파일의 body.type,  접두어로 사용됨, 나중에 서버쪽에서 api를 호출해 정식 폴더로 옮김
) => {
  const fileKey = Str.randomAlphaNumericStr(10);
  console.log('fileObj', fileObj);
  const data = new FormData();
  let url = '';

  if (kind === 'postImage') {
    //
    const imageInfo = await ImageHelper.getImageInfoFromFile(fileObj);

    if (!imageInfo) {
      // todo 실패 처리
      return;
    }

    const { width, height, base64ThumbnailData } = imageInfo;

    console.log('width=', width);
    console.log('height=', height);
    console.log('base64Data=', base64ThumbnailData);
    sendNativeEvent(
      EVENT_TYPE_UPLOAD_SINGLE,
      JSON.stringify({
        type: 'image',
        fileKey,
        uploadStatus: STARTED,
        fileName: fileObj.name,
        mimeType: fileObj.type,
        bytesUploaded: 0,
        contentLength: fileObj.size,
        imageWidth: width,
        imageHeight: height,
        base64Data: base64ThumbnailData,
        postIdentifier,
      }),
    );
    url = `${Config.fileServerBaseUrl}/${kind}`;
    data.append('academyId', academyId.toString());
    data.append(SINGLE_IMAGE_FIELD, fileObj);
    //
  } else if (kind === 'postAttachment') {
    //
    sendNativeEvent(
      EVENT_TYPE_UPLOAD_SINGLE,
      JSON.stringify({
        type: 'attachment',
        fileKey,
        uploadStatus: STARTED,
        fileName: fileObj.name,
        mimeType: fileObj.type,
        bytesUploaded: 0,
        contentLength: fileObj.size,
        postIdentifier,
      }),
    );
    url = `${Config.fileServerBaseUrl}/${kind}`;
    data.append('academyId', academyId.toString());
    data.append(SINGLE_ATTACHMENT_FIELD, fileObj);
    //
  } else if (kind === 'tempImage') {
    //
    // inapp 에서 uploadTempImage는 fileUploader의 uploadImage를 이용한다.
    url = `${Config.fileServerBaseUrl}/tempFile`;
    data.append('academyId', academyId.toString());
    data.append(SINGLE_TEMPFILE_FIELD, fileObj);
    // tempFile 은 type을 필요로 한다.
    // 어떤 작업을 위한 임시파일인지
    if (!tempFileType) {
      throw new Error('tempFile must come with type');
    }
    data.append('type', tempFileType);
  }

  const onUploadProgress = (progressEvent) => {
    sendNativeEvent(
      EVENT_TYPE_UPLOAD_SINGLE,
      JSON.stringify({
        fileKey,
        uploadStatus: UPLOADING,
        fileName: fileObj.name,
        mimeType: fileObj.type,
        bytesUploaded: progressEvent.loaded,
        contentLength: fileObj.size,
        postIdentifier,
      }),
    );
  };
  const accessToken = getAccessToken();
  const config = {
    onUploadProgress,
    headers: { authorization: `Bearer ${accessToken}` },
  };
  axios.post(url, data, config).then((res) => {
    console.log('upload end, response', res.data);
    const uploadEvent = {
      fileKey,
      uploadStatus: COMPLETE,
      fileName: fileObj.name,
      mimeType: fileObj.type,
      bytesUploaded: fileObj.size,
      contentLength: fileObj.size,
      path: res.data.filePath,
      postIdentifier,
    };
    sendNativeEvent(EVENT_TYPE_UPLOAD_SINGLE, JSON.stringify(uploadEvent));

    // 단일 파일 요청 등 eventIdentifier가 주어지고
    // 성공, 실패 등 최종 이벤트일 때는 결과를 넣어줌
    if (eventIdentifier) {
      console.log('eventIdentifier = ', eventIdentifier);
      generalInterface.requestProcessed(
        eventIdentifier,
        Base64.encode(JSON.stringify(uploadEvent)),
      );
    }
  });
};

const uploadPostImage = (messageObj, sender) => {
  if (messageObj.action !== 'uploadPostImage') {
    return { handled: false };
  }

  const uploadElem = document.createElement('input');
  uploadElem.setAttribute('type', 'file');
  uploadElem.setAttribute('accept', 'image/jpeg,image/png');
  uploadElem.setAttribute('multiple', 'true');
  uploadElem.click();

  uploadElem.addEventListener('change', function () {
    if (uploadElem.files && uploadElem.files.length) {
      const files = [...uploadElem.files];
      if (files.length > messageObj.maxCount) {
        alert(`${messageObj.maxCount}개의 이미지만 업로드합니다.`);
        files.splice(messageObj.maxCount);
      }

      console.log('uploadElem.files', files);
      for (let i = 0; i < files.length; ++i) {
        uploadFile(
          messageObj.academyId,
          messageObj.postIdentifier,
          'postImage',
          files[i],
          '', // tempFileType 은 kind === 'tempImage' 일때만 필요
        );
      }
    }
  });

  return {
    handled: true,
  };
};

const uploadPostAttachment = (messageObj, sender) => {
  if (messageObj.action !== 'uploadPostAttachment') {
    return { handled: false };
  }

  console.log('uploadPostAttachment helper');

  const uploadElem = document.createElement('input');
  uploadElem.setAttribute('type', 'file');
  uploadElem.setAttribute('accept', '*/*');
  uploadElem.setAttribute('multiple', 'false'); // 첨부파일은 하나씩
  uploadElem.click();

  uploadElem.addEventListener('change', function () {
    if (uploadElem.files && uploadElem.files.length) {
      let files = [...uploadElem.files];
      if (files.length > messageObj.maxCount) {
        alert(`${messageObj.maxCount}개의 파일만 업로드합니다.`);
        files.splice(messageObj.maxCount);
      }

      console.log('uploadElem.files', files);

      for (let i = 0; i < uploadElem.files.length; ++i) {
        uploadFile(
          messageObj.academyId,
          messageObj.postIdentifier,
          'postAttachment',
          files[i],
          '', // tempFileType 은 kind === 'tempImage' 일때만 필요
        );
      }
    }
  });

  return {
    handled: true,
  };
};

const uploadTempImage = (messageObj, sender) => {
  if (messageObj.action !== 'uploadTempImage') {
    return { handled: false };
  }

  /*
  messageObj 구성
      academyId,
      type,
      maxWidth,
      ratioW,
      ratioH,
  */

  const uploadElem = document.createElement('input');
  uploadElem.type = 'file';
  uploadElem.accept = 'image/jpeg,image/png';
  uploadElem.multiple = false;
  uploadElem.click();

  uploadElem.addEventListener('change', async function () {
    if (uploadElem.files && uploadElem.files.length) {
      const file = uploadElem.files[0];
      let image = await ImageHelper.getImageFromFile(file);

      if (!image) {
        interfaceBridge.customAlert(
          '이미지 읽기 실패',
          '이미지를 읽을 수 없습니다.',
          '닫기',
        );
        return;
      }

      console.log('uploadTempImage', messageObj);

      // 프로필 이미지 요청인 경우 crop 진행
      if (
        messageObj.type === 'userProfile' ||
        messageObj.type === 'studentProfile'
      ) {
        image = ImageHelper.cropCenter(
          image,
          ServiceConfig.User.maxProfileImageSize,
          1,
          1,
        );
      } else if (
        messageObj.ratioW === 0 &&
        messageObj.ratioH === 0 &&
        messageObj.maxWidth // 비율이 없을 때 maxWidth는 maxLongSide의 역할을 한다.
      ) {
        image = ImageHelper.resize(image, messageObj.maxWidth);
      }

      if (!image) {
        interfaceBridge.customAlert(
          '이미지 변환 실패',
          '이미지의 크기를 조절하는데 실패했습니다.',
          '닫기',
        );
        return;
      }

      // resize하면서 toDataUrl 로 넣었음
      const dataUrl = image.src;
      const blob = dataURItoBlob(dataUrl);
      const ext = blob.type === 'image/jpeg' ? 'jpg' : 'png';
      console.log('image ext = ', ext);
      const fileToUpload = new File([blob], `image.${ext}`, {
        type: blob.type,
      });

      uploadFile(
        messageObj.academyId,
        'dummy',
        'tempImage',
        fileToUpload,
        messageObj.identifier,
        messageObj.type, // temp 파일의 body.type,  접두어로 사용됨, 나중에 서버쪽에서 api를 호출해 정식 폴더로 옮김
      );
    }
  });

  return {
    handled: true,
  };
};

export { uploadPostImage, uploadPostAttachment, uploadTempImage };
