export const Chat = {
  Room: {
    Type: {
      academyParent: 1,
      teacherParent: 2,
      academyStudent: 3,
      teacherStudent: 4,
    },
  },
  Message: {
    Type: {
      text: 1,
      // image: 2,

      privateMessageFromServer: 90,
      publicMessageFromServer: 91,
      localMessage: 92,
    },
  },
  Event: {
    // 채팅방 메시지 관련
    JOIN_ROOM: 'join_room',
    LEAVE_ROOM: 'leave_room',
    NEW_MESSAGE: 'new_message',
    /*
      parent는 토큰을 통해 자신의 학생들이 들어있는 방의 업데이트를 구독한다. (key2 가 일치할때 알려줌)
      일반교사는 토큰을 통해 자신의 방의 업데이트를 구독한다. (key1이 일치할 때 알려줌)
      관리자 운영자는 검색이 가능함
        - 자신 구독
        - 상담창구 구독
        - 모든 방 구독
      업데이트는 방 정보 형태와 동일하게 준다. (없던 방일 수도 있으므로)
    */
    SUBSCRIBE_TO_ROOM_UPDATES: 'subscribe_to_room_updates',
    UNSUBSCRIBE_FROM_ROOM_UPDATES: 'unsubscribe_from_room_updates',
    ROOM_UPDATE: 'room_update',
    PING: 'ping',
  },
  SocketioEvent: {
    CONNECT: 'connect',
    CONNECT_ERROR: 'connect_error',
    DISCONNECT: 'disconnect',
    RECONNECT: 'reconnect',
  },
};
