import { SettingsConsumer, SettingsProvider } from 'contexts/settingsContext';
import { RootProvider } from 'contexts/rootContext';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from 'theme/index';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import Navigator from 'presenters/util/Navigator';
import MyAlertDialog from 'presenters/MyAlertDialog/MyAlertDialog';
import RouteList from './routers/RouteList';
import { LayoutProvider } from 'contexts/layoutContext';

const App = (): JSX.Element => {
  return (
    <HelmetProvider>
      <SettingsProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <RootProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    direction: settings.direction,
                    responsiveFontSizes: settings.responsiveFontSizes,
                    mode: 'dark', // settings.theme,
                  })}
                >
                  <LayoutProvider>
                    <CssBaseline />
                    <Navigator />
                    <MyAlertDialog />

                    <RouteList />
                    {/* <AlertContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
                    {/* <SnackbarContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
                    {/* <PopupContainer/>  컨테이너는 alertstore에 접근을 하고, 각 컴포넌트는 useRootStore.alertStore or useAlertStore  */}
                  </LayoutProvider>
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </RootProvider>
        </LocalizationProvider>
      </SettingsProvider>
    </HelmetProvider>
  );
};

export default App;
