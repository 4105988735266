import { SortResultT } from 'types/frontend/shared';

class ArrayHelper {
  // 단방향, object2의 내용이 모두 object1 에 있는지 확인한다.
  static deepEqual = (object1, object2) => {
    const isObject = (object) => {
      return object != null && typeof object === 'object';
    };

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length < keys2.length) {
      console.log('key 길이 모자람', keys1, keys2);
      return false;
    }
    for (const key of keys2) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !this.deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  };

  static mergeArray = <T, KeyT>(
    existingData: T[],
    newData: T[],
    keyField: keyof T,
    sortingCriteria: (param1: T, param2: T) => SortResultT,
  ): KeyT[] => {
    const deletedItems: KeyT[] = [];

    let eIdx = 0;
    let nIdx = 0;

    while (eIdx < existingData.length || nIdx < newData.length) {
      // 기존 data 확인 모두 진행됨, 새 data 남아있음
      if (eIdx >= existingData.length && nIdx < newData.length) {
        existingData.push(newData[nIdx++]);
        eIdx++;

        continue;
      }
      // 기존 data 남아있음, 새 data 확인 모두 진행됨
      if (eIdx < existingData.length && nIdx >= newData.length) {
        deletedItems.push(existingData[eIdx][keyField] as unknown as KeyT);
        existingData.splice(eIdx, 1);
        continue;
      }

      // 추가할 필요 없음
      if (existingData[eIdx][keyField] === newData[nIdx][keyField]) {
        // 동일한 항목에 대해 내용 비교
        if (this.deepEqual(existingData[eIdx], newData[nIdx])) {
          eIdx++;
          nIdx++;
        } else {
          existingData[eIdx] = newData[nIdx];
        }
      } else {
        if (sortingCriteria(existingData[eIdx], newData[nIdx]) < 0) {
          // 새 데이터 중 existingData 가 없어진 경우
          deletedItems.push(existingData[eIdx][keyField] as unknown as KeyT);
          existingData.splice(eIdx, 1);
        } else {
          // existingData에 없는 새 데이터 들어옴
          existingData.splice(eIdx++, 0, newData[nIdx++]);
        }
      }
    } // end of while

    return deletedItems;
  };

  // reference를 바꾸지 않으면서 내용 교체
  static replaceArray = <T>(targetArray: T[], newData: T[]) => {
    targetArray.length = 0;
    targetArray.push(...newData);
  };

  static numberArray = (count: number) => {
    return Array.from(Array(count).keys());
  };
}
export default ArrayHelper;
