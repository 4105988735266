import { API } from 'types/frontend/shared';

//
//
//
// 교사 관련
//

// 교사 리스트
export const READ_TEACHER_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/teacher`,
});

// 교사 리스트 - 간단버전
export const READ_SIMPLE_TEACHER_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/teacher?simple=true`,
});

// 교사 상세
export const READ_TEACHER_DETAIL = (
  academyId: number,
  teacherId: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/teacher/${teacherId}`,
});

// 교사의 클래스 배정
export const UPDATE_TEACHER_CLS_ASSIGNMENT = (
  academyId: number,
  teacherId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/teacher/${teacherId}/clsAssignment`,
});

// 교사 추가
export const CREATE_TEACHER = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/teacher`,
});

// 교사 수정
export const UPDATE_TEACHER = (academyId: number, teacherId: number): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/teacher/${teacherId}`,
});

// 교사 상태 수정
export const UPDATE_TEACHER_STATUS = (
  academyId: number,
  teacherId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/teacher/${teacherId}/status`,
});

// 교사 삭제
export const DELETE_TEACHER = (academyId: number, teacherId: number): API => ({
  method: 'DELETE',
  path: `/academy/${academyId}/teacher/${teacherId}`,
});
