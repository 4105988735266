// interfaceBridge에 requestNativeFeature 를 제공
/* ios/and/iframe 용 test를 걷어내고 pcBrowserInterface로 연결 */

import Base64 from 'utils/Base64';
import Str from 'utils/Str';
import pcBrowserInterface from './pcBrowserInterface';

console.log('interface loaded');

// 요청시 실시간으로 실행할 함수들이 등록된다.
const interfaceResultHandler = {};
// 이벤트가 들어오면 처리할 함수들이 등록된다. 없으면 처리안되고 끝남
const nativeEventHandler = undefined;

// 네이티브에서 넘어오는 이벤트를 처리해줄 함수
function onNativeEvent(eventType, encodedJsonStr) {
  console.log('native event received');
  if (
    window.nativeEventHandler &&
    typeof window.nativeEventHandler === 'function'
  ) {
    console.log('nativeEventHandler exists');
    const decodedEventStr = Base64.decode(encodedJsonStr);
    console.log(`decoded event str =[${decodedEventStr}]`);
    let eventData;
    try {
      eventData = JSON.parse(decodedEventStr);
    } catch (e) {
      if (e instanceof Error) {
        console.log('json parse error=', e);
        alert(decodedEventStr);
      }
    }
    if (eventData) {
      window.nativeEventHandler(eventType, eventData);
    }
  } else {
    if (window.nativeEventHandler) {
      console.log('event handler type', typeof window.nativeEventHandler);
    }
  }
}

// wrapper function
function requestNativeFeature(
  obj,
  resultHandler,
  shouldReturnSynchronously /* 즉시 return 할지 */,
) {
  // console.log('requestNativeFeature called', obj);
  if (resultHandler) {
    const identifier = 'resultHandler_' + Str.randomAlphaNumericStr(10);
    obj.identifier = identifier;
    interfaceResultHandler[identifier] = resultHandler;
  } else {
    obj.identifier = 'null';
  }

  let returnValue = pcBrowserInterface.requestNativeFeature(
    JSON.stringify(obj),
  );

  if (shouldReturnSynchronously) {
    returnValue = returnValue || '';
    // console.log('native feature return value', returnValue);

    const decodedResult = Base64.decode(returnValue);

    // console.log('native feature decodedResult', decodedResult);
    return decodedResult;
  }
}

// 요청을 처리한 후 응답을 받아줄 함수
function requestProcessed(identifier, result) {
  console.log('this page is ', window.location.href);
  if (identifier && identifier !== 'null') {
    if (typeof interfaceResultHandler[identifier] === 'function') {
      const resultHandler = interfaceResultHandler[identifier];

      // result는 넘어오지 않을 수도 있다.
      // falsy한 값일 경우 null로 통일
      // console.log('encodedResult', result);

      const decodedResult = result ? Base64.decode(result) : null;

      // console.log('decodedResult', decodedResult);

      resultHandler(decodedResult);
    } else {
      // console.log('no resultHandler for ' + identifier);
      // console.log(
      //   'window.interfaceResultHandler=',
      //   window.interfaceResultHandler,
      // );
      // console.log(
      //   ' window.interfaceResultHandler[identifier]=',
      //   window.interfaceResultHandler[identifier],
      // );
    }
  } else {
    // console.log('no identifier');
  }
}

const generalInterface = {
  requestNativeFeature,
  onNativeEvent,
  requestProcessed,
};
export default generalInterface;
