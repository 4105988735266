import { MyTeacherT, SortResultT } from 'types/frontend/shared';
import { TeacherT } from 'types/shared/core/Teacher';
import { EmptyRequestT } from 'types/shared/reqAndRes/CommonRequest';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { READ_TEACHER_LIST } from 'network/shared/teacher';
import BaseStore from 'stores/BaseStore';
import ClsDataStore from 'stores/shared/ClsDataStore';
import { api } from 'utils/api/api';
import { makeAutoObservable, reaction } from 'mobx';

import SC from 'constants/shared';

import ArrayHelper from 'utils/ArrayHelper';

console.log('TeacherListCommonStore');

const teacherSortingCriteria = (
  t1: MyTeacherT,
  t2: MyTeacherT,
): SortResultT => {
  if (SC.Teacher.Position[t1.position] < SC.Teacher.Position[t2.position])
    return -1;
  if (SC.Teacher.Position[t1.position] > SC.Teacher.Position[t2.position])
    return 1;

  if (t1.name < t2.name) return -1;
  if (t1.name > t2.name) return 1;

  return t1.teacherId === t2.teacherId
    ? 0
    : t1.teacherId < t2.teacherId
      ? -1
      : 1;
};

//
//
//
//
//
//

export default class TeacherListDataStore implements BaseStore {
  teacherListVersion = ''; // 외부에서 변할 필요가 있을 때 설정하는 값
  teacherListTimestamp = ''; // 내부에서 불러오기 완료시 설정하는 값

  clsDataStore: ClsDataStore;

  teacherItemList: MyTeacherT[] = [];
  teacherIdAndTeacherMap: Map<number, MyTeacherT> = new Map();

  isInited = false;

  constructor(clsDataStore: ClsDataStore) {
    makeAutoObservable(this);

    this.clsDataStore = clsDataStore;

    reaction(
      () => this.clsDataStore.clsTimestamp,
      () => this.makeTeacherClassDescription(),
    );
    reaction(
      () => this.teacherListVersion,
      () => this.loadTeacherList(),
    );
  }

  setVersion = (v: string) => {
    this.teacherListVersion = v;
  };

  makeTeacherClassDescription = () => {
    console.log('teacher desc 만들기', this.clsDataStore.teacherClassAssignMap);

    this.teacherItemList.forEach((teacher) => {
      teacher.assignedClassNames = [];
      this.clsDataStore.teacherClassAssignMap
        .get(teacher.teacherId)
        ?.forEach((clsId) => {
          const cls = this.clsDataStore.clsMap.get(clsId);
          if (!cls) return;
          teacher.assignedClassNames?.push(cls.name);
        });
      teacher.description = teacher.assignedClassNames.join(', ');
    });
  };

  // 새로운 교사 배열을 받아오면
  // 기존 배열과 merge 하면서 삭제된 것도 추려낸다.
  updateTeacherList = (list: TeacherT[]) => {
    const start = performance.now();
    console.log('updateTeacherList, listSize = ', list.length);

    const deletedItems = ArrayHelper.mergeArray<MyTeacherT, number>(
      this.teacherItemList,
      list.sort(teacherSortingCriteria),
      'teacherId',
      teacherSortingCriteria,
    );

    deletedItems.forEach((id) => {
      this.teacherIdAndTeacherMap.delete(id);
    });
    this.teacherItemList.forEach((teacher) => {
      this.teacherIdAndTeacherMap.set(teacher.teacherId, teacher);
    });

    this.makeTeacherClassDescription();

    this.teacherListTimestamp = new Date().toString();
  };

  loadTeacherList = async () => {
    const start = performance.now();
    try {
      const result = await api<EmptyRequestT, MyResponse<TeacherT>>(
        READ_TEACHER_LIST(0),
        {},
      );

      console.log('loadTeacherList result', result);

      if (result.isSuccess && result.objects) {
        console.log('wow');
        console.log('updateTeacherList took ms : ', performance.now() - start);
        this.updateTeacherList(result.objects as TeacherT[]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  init = () => {
    console.log('init');
    this.isInited = true;
    this.loadTeacherList();
  };
}
