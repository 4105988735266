const role = {
  parent: 1,
  subscriber: 2, // parent는 다른사람 구독을 하기 시작하면 subscriber가 된다.
  student: 3,
  teacher: 5, // director는 teacher의 position으로 구분한다.

  attendance: 90, // 출결 기록
  nobody: 98, // 서버가 사용자에게 뭔가 남김, 또는 무명인
  admin: 99,
};

export const User = {
  Role: role,

  // role의 일부에 director만 더해졌다. director 이외의 상수는 동일
  PushReceiver: {
    parent: role.parent,
    subscriber: role.subscriber,
    student: role.student,
    teacher: role.teacher,
    director: 10,
  },
};

export const Agreement = {
  Type: {
    tos: 1,
    pp: 2,
  },
};
