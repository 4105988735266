import { AccessTokenPayloadT } from 'types/shared/core/Token';

import Token from 'utils/Token';

// 현재 페이지에 관한 최소한의 정보
class PageStore {
  activeAcademyId = 0;

  constructor() {
    const accessToken = Token.getToken('accessToken');
    const accessTokenPayload = Token.getAccessTokenPayload(accessToken);

    if (accessTokenPayload?.role === 'teacher') {
      const activeAcademyId = accessTokenPayload.academyId;
      if (!activeAcademyId) throw new Error('academyId is null');
      this.activeAcademyId = activeAcademyId;
    } else {
      console.log('role = ', accessTokenPayload?.role);
    }
  }

  updateActiveAcademyId = (academyId: number) => {
    this.activeAcademyId = academyId;
    console.log('new activeAcademyId', this.activeAcademyId);
  };
}

export default PageStore;
