import interfaceBridge from 'utils/interfaceBridge';
import Token from 'utils/Token';
import { useEffect } from 'react';
import Val from 'constants/frontend/shared/Val';

function Start() {
  // 로그인 되어있는지 확인
  useEffect(() => {
    const refreshToken = Token.getToken('refreshToken');
    if (refreshToken === '') {
      interfaceBridge.nav('/user/signIn', true);
    } else {
      interfaceBridge.nav(Val.INITIAL_URL_AFTER_SIGNING_IN, true);
    }
  }, []);
  return null;
}

export default Start;
