export const Homework = {
  Grade: {
    'A+': 100,
    'A': 95,
    'B': 85,
    'C': 75,
    'D': 65,
    'F': 50,
  },

  Difficulty: {
    easy: 10,
    medium: 20,
    hard: 30,
  },

  // 보여지는 완성도 - 보여지는
  PresentationQuality: {
    exceeds: 100,
    meets: 90,
    below: 80,
  },

  Preset: {
    SourceType: {
      academy: 1,
      teacher: 2,
      contentSupplier: 3,
      anotherClass: 50,
    },
  },

  Reviewer: {
    assignerOnly: 1,
    withManagers: 2,
    withAssignedTeachers: 3,
  },
};

/*
A+: 97-100
A: 93-96
A-: 90-92
B+: 87-89
B: 83-86
B-: 80-82
C+: 77-79
C: 73-76
C-: 70-72
D+: 67-69
D: 63-66
D-: 60-62
F: Below 60
*/
