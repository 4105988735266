import React from 'react';
import { Route } from 'react-router-dom';

//
// 4) 글쓰기
//
const BoardPostWriteEdit = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/BoardPostWriteEdit/BoardPostWriteEdit'
    ),
);
const NotePostWriteEdit = React.lazy(
  () =>
    import(
      'pages/teacherSide/postWriteEdit/NotePostWriteEdit/NotePostWriteEdit'
    ),
);
const TeacherPostView = React.lazy(
  () => import('pages/teacherSide/postView/TeacherPostView/TeacherPostView'),
);
// const PersonalMessage = React.lazy(
//   () =>
//     import(
//       'pages/teacherSide/postWrite/WritePersonalMessage/WritePersonalMessage'
//     ),
// );
// const YoutubeLink = React.lazy(
//   () => import('pages/teacherSide/postWrite/YoutubeLink/YoutubeLink'),
// );

// const WritableBoardList = React.lazy(
//   () =>
//     import('pages/teacherSide/postWrite/WritableBoardList/WritableBoardList'),
// );

// // 글보기
// const TeacherPostView = React.lazy(
//   () => import('pages/teacherSide/postView/TeacherPostView/TeacherPostView'),
// );

// const ViewPersonalMessage = React.lazy(
//   () =>
//     import(
//       'pages/teacherSide/postView/ViewPersonalMessage/ViewPersonalMessage'
//     ),
// );

const RouteListTSPost = (
  <Route path="teacherSide/news/">
    {/* 쓰기 */}
    <Route
      path="writeBoardPost"
      element={<BoardPostWriteEdit mode="write" />}
    />
    <Route path="writeNotePost" element={<NotePostWriteEdit mode="write" />} />
    <Route
      path="editBoardPost/:postId"
      element={<BoardPostWriteEdit mode="edit" />}
    />
    <Route
      path="editNotePost/:postId"
      element={<NotePostWriteEdit mode="edit" />}
    />

    <Route
      path="notePost/:postId"
      element={<TeacherPostView postType="notePost" />}
    />

    <Route
      path="boardPost/:postId"
      element={<TeacherPostView postType="boardPost" />}
    />
  </Route>
);

export default RouteListTSPost;
