import React from 'react';
import { Route } from 'react-router-dom';

const BillCorpSettingAddEdit = React.lazy(
  () =>
    import(
      'pages/teacherSide/bill/BillCorpSettingAddEdit/BillCorpSettingAddEdit'
    ),
);
const BillCorpSettingList = React.lazy(
  () =>
    import('pages/teacherSide/bill/BillCorpSettingList/BillCorpSettingList'),
);
const BillPresetList = React.lazy(
  () => import('pages/teacherSide/bill/BillPreset/BillPresetList'),
);
const BillSetting = React.lazy(
  () => import('pages/teacherSide/bill/BillSetting/BillSetting'),
);

// 청구서
const BillGroupList = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupList/BillGroupList'),
);
const BillGroupAddEdit = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupAddEdit/BillGroupAddEdit'),
);
const BillGroupDetail = React.lazy(
  () => import('pages/teacherSide/bill/BillGroupDetail/BillGroupDetail'),
);

// 조회
const BillPaymentList = React.lazy(
  () => import('pages/teacherSide/bill/BillPaymentList/BillPaymentList'),
);

const UnpaidBillList = React.lazy(
  () => import('pages/teacherSide/bill/UnpaidBillList/UnpaidBillList'),
);

// 템플릿 - 청구서와 짝을 이룬다.
const BillTemplateList = React.lazy(
  () => import('pages/teacherSide/bill/BillTemplateList/BillTemplateList'),
);
const BillTemplateAddEdit = React.lazy(
  () =>
    import('pages/teacherSide/bill/BillTemplateAddEdit/BillTemplateAddEdit'),
);

// 현금영수증
const CashBillInfo = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillInfo/CashBillInfo'),
);
const CashBillIssue = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillIssue/CashBillIssue'),
);
const CashBillIssueWOBill = React.lazy(
  () =>
    import(
      'pages/teacherSide/cashBill/CashBillIssueWOBill/CashBillIssueWOBill'
    ),
);
const CashBillList = React.lazy(
  () => import('pages/teacherSide/cashBill/CashBillList/CashBillList'),
);

// 영수증

const BillReceipt = React.lazy(
  () => import('pages/teacherSide/bill/BillReceipt/BillReceipt'),
);

const RouteListTSBill = (
  <Route path="/teacherSide/bill/">
    {/* <Route path="usageGuide" element={<BillUsageGuide />} /> */}

    <Route path="setting" element={<BillSetting />} />

    <Route path="corpSetting/">
      <Route path="list" element={<BillCorpSettingList />} />
      <Route path="add" element={<BillCorpSettingAddEdit mode="add" />} />
      <Route
        path="edit/:corpNo"
        element={<BillCorpSettingAddEdit mode="edit" />}
      />
    </Route>

    <Route path="preset/list" element={<BillPresetList />} />

    {/* <Route path="create" element={<BillCreate />} /> */}

    <Route path="group/">
      <Route path="list" element={<BillGroupList />} />
      <Route path="add" element={<BillGroupAddEdit mode="add" />} />
      <Route path="detail/:billGroupId" element={<BillGroupDetail />} />
      <Route
        path="edit/:billGroupId"
        element={<BillGroupAddEdit mode="edit" />}
      />
      <Route
        path="editAfterSending/:billGroupId"
        element={<BillGroupAddEdit mode="editAfterSending" />}
      />
    </Route>

    <Route path="payment/list" element={<BillPaymentList />} />
    <Route path="unpaidBill/list" element={<UnpaidBillList />} />

    <Route path="template/">
      <Route path="list" element={<BillTemplateList />} />
      <Route path="add" element={<BillTemplateAddEdit mode="add" />} />
      <Route
        path="edit/:billTemplateGroupId"
        element={<BillTemplateAddEdit mode="edit" />}
      />
    </Route>

    <Route path="cashBill/">
      <Route path="info" element={<CashBillInfo />} />
      <Route path="issue" element={<CashBillIssue />} />
      <Route path="issueWOBill" element={<CashBillIssueWOBill />} />
      <Route path="list" element={<CashBillList />} />
    </Route>

    <Route path="receipt" element={<BillReceipt />} />
  </Route>
);

export default RouteListTSBill;
