export const Academy = {
  Type: {
    academy: 1,
    schoolHouse: 2,
    privateLesson: 3,
    etc: 99,
  },
  Subject: {
    korean: 1,
    english: 2,
    math: 4,
    science: 8,
    socialStudies: 16,
    music: 32,
    art: 64,
    pe: 128,
    coding: 256,
    etc: 65536,
  },
};

export const Cls = {
  Status: {
    inOperation: 1,
    closed: 2,
    deleted: 3,
  },
};

export const Student = {
  Status: {
    enrolled: 1,
    notEnrolled: 2,
    deleted: 3,
  },
};

export const Teacher = {
  Position: {
    director: 10,
    assistantDirector: 20,
    regularTeacher: 40,
  },
  Status: {
    employed: 1,
    resigned: 2,
    deleted: 3,
  },
};

export const DashboardStats = {
  maxRecentDays: 31,
  maxRecentMonths: 13,
};
