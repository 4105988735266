import { EmptyRequestT } from 'types/shared/reqAndRes/CommonRequest';
import { MyResponse } from 'types/shared/reqAndRes/MyResponse';
import { MyInfoT } from 'types/shared/core/User';
import { READ_MY_INFO } from 'network/shared/user';
import { api } from 'utils/api/api';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { AcademySettingT } from 'types/shared/core/Academy';
import { getActiveAcademyId } from './RootStore';

class UserStore {
  myInfo: MyInfoT | null = null;
  myInfoVersion = '';
  myInfoTimestamp = '';

  pagesWithoutMyInfo = [
    '/user/start',
    '/user/welcome',
    '/user/signIn',
    '/user/signUp',
    '/user/agreement',
    '/user/agreementDetail',
    '/user/selectRole',
    '/user/verifyPhone',
  ];

  constructor() {
    makeAutoObservable(this, { loadMyInfo: false });

    // 생성과 함께 언제나 실행
    if (!this.pagesWithoutMyInfo.includes(window.location.pathname)) {
      this.loadMyInfo();
    }

    reaction(
      () => this.myInfoVersion,
      () => this.loadMyInfo(),
    );
  }

  setVersion = (v: string) => {
    this.myInfoVersion = v;
    console.log('userStore.myInfoVersion : ', this.myInfoVersion);
  };

  // 자신의 정보 불러오기
  loadMyInfo = async () => {
    console.log('userStore.loadMyInfo 호출됨');
    try {
      const response = await api<EmptyRequestT, MyResponse<MyInfoT>>(
        READ_MY_INFO,
        {},
      );

      console.log('MY_INFO response', response);

      if (response.isSuccess) {
        runInAction(() => {
          this.myInfo = response.object as MyInfoT;
          this.myInfoTimestamp = new Date().toString();
        });
      } else {
        // 내 정보 읽어오지 못함
      }
    } catch (e) {
      // 오류
    }
  };

  get isDirector(): boolean {
    return this.myInfo?.teacherInfo?.teacher.position === 'director';
  }

  get isManager(): boolean {
    return (
      this.myInfo?.teacherInfo?.teacher.position === 'director' ||
      this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector'
    );
  }

  get isAssistantDirector(): boolean {
    return this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector';
  }

  // 운영자&학원비 담당 또는 원장
  get isFinancialManager(): boolean {
    return (
      (this.myInfo?.teacherInfo?.teacher.position === 'assistantDirector' &&
        this.myInfo?.teacherInfo?.teacher.isFinancialManager) ||
      this.myInfo?.teacherInfo?.teacher.position === 'director'
    );
  }

  get isTeacher(): boolean {
    return this.myInfo?.userInfo.role === 'teacher';
  }

  get activeAcademySetting(): AcademySettingT | null {
    const activeAcademyId = getActiveAcademyId();
    if (!activeAcademyId) return null;

    if (this.isTeacher) {
      const academy = this.myInfo?.teacherInfo?.academy;
      if (!academy) return null;

      return {
        insiderSetting: academy.insiderSetting,
        publicSetting: academy.publicSetting,
      };
    }
    return null;
  }
}

export default UserStore;
