// 어나더클래스 전역에서 쓰일 enum형태의 상수들을 정의
import { Academy, Cls, Student, Teacher, DashboardStats } from './Aca';
import { Common } from './Common';
import { Post } from './Post';
import { Board } from './Board';
import { User, Agreement } from './User';
import { Permission } from './Permission';
import { Device } from './Device';
import { Bill } from './Bill';
import { Attendance } from './Attendance';
import { Chat } from './Chat';
import { AcaBillingAndPayment } from './AcaBillingAndPayment';
import { Message } from './Message';
import { Homework } from './Homework';

const SC = {
  User,
  Agreement,
  Academy,
  Cls,
  Student,
  Teacher,
  Post,
  Board,
  Permission,
  Device,
  Chat,
  Attendance,
  Bill,
  Common,
  AcaBillingAndPayment,
  Message,
  Homework,
  DashboardStats,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function prepareByValueObj(obj: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newObj: Record<string, any> = {};
  Object.keys(obj).forEach((key) => {
    if (key === 'byValue') {
      return;
    }
    if (typeof obj[key] === 'object') {
      prepareByValueObj(obj[key]);
    } else if (typeof obj[key] === 'string' || typeof obj[key] === 'number') {
      const val = obj[key];
      newObj[val] = key;
    }
  });
  if (Object.keys(newObj).length !== 0) {
    obj.byValue = newObj;
  }
  return newObj;
}

prepareByValueObj(SC);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function keyByValue(obj: any, value: any) {
  return obj.byValue[value];
}

export default SC;
