export const Bill = {
  CorpSetting: {
    Type: {
      epay: 1,
      cash: 10,
    },
    Status: {
      registered: 10,
      activated: 20,
      suspended: 30,
      stopped: 40,
      deleted: 50,
    },
  },
  Group: {
    Status: {
      created: 1,
      sent: 2,
      deleted: 3,
    },
  },
  Account: {
    Method: {
      use: 1,
      carrieOverMoney: 2, // 이월금
      advancedPayment: 3, // 선납금
      deductionWhileBillIssuing: 3, // 청구서 발행중 차감
      restorationWhileBillCanceling: 4, // 청구서 취소중 복원
      useToPay: 5, // 결제하는데 사용
      errorCorrection: 6, // 오류수정
    },
  },
  CashBill: {
    Kind: {
      incomeTaxDeduction: 1,
      evidenceOfExpenditure: 2,
    },
    Status: {
      created: 1,
      errorOnIssuing: 299,
      issued: 300,
      successful: 304,
      failed: 305,
      canceled: 400,
    },
  },
  Item: {
    Kind: {
      tuition: 1, // 수강료
      material: 3, // 재료비
      meal: 4, // 급식비
      clothing: 5, // 피복비
      exam: 6, // 모의고사비
      textbook: 19, // 교재비
      etc: 20, // 기타
      // 기숙사비 뺐음
    },
  },
  Preset: {
    Type: {
      normal: 1, // 일반항목
      discount: 2, // 할인항목
    },
  },
  Status: {
    created: 1,
    sent: 2,
    deleted: 3,
    canceled: 4,
    closed: 5,
    complete: 6, // 완납
  },
  Payment: {
    Method: {
      onlineCard: 1,
      wireTransfer: 2,
      offlineCard: 3,
      offlineCash: 4,
      accountBalance: 5, // 원비통장 차감
      localCurrency: 6,

      etcPaymentMethod: 9, // 기타 방법 사용

      onlineCardCancel: 21,
      wireTransferRefund: 22,
      offlineCardCancel: 23,
      offlineCashRefund: 24,
      accountBalanceRefund: 25, // 원비통장 환원
      localCurrencyCancel: 26,

      etcPaymentMethodRefund: 29, // 기타 방법 환원

      memo: 40,
    },
    // 현금영수증과 관련된 현금성 수납/환불 방법
    MethodWithCash: [
      'wireTransfer',
      'offlineCash',
      'wireTransferRefund',
      'offlineCashRefund',
    ],
    OnlineStatus: {
      started: 0,
      success: 10,
      sslComError: 40,
    },
    CardOwner: ['a', 'b', 'c'],
  },
  Notification: {
    BillSent: {
      MessageType: {
        default: 0,
        reminder: 1,
        update: 2, // 수정발행
      },
    },
    Method: {
      none: 0,
      push: 1,
      pushAndSms: 2,
    },
  },
  Template: {
    IssueStrategy: {
      manual: 1,
      autoIndividualByDay: 2,
      // autoGroupByDay: 3,
      autoIndividualByCount: 4,
      // autoGroupByCount: 5,
    },
  },
};
