import { useEffect } from 'react';
import { useRootStore } from 'hooks/useRootStore';

import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

const Navigator = observer(() => {
  const { navigationStore } = useRootStore();
  const destination = navigationStore.destination;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('Navigator useEffect', destination);
    if (destination.to === '-1') {
      // 현재 페이지에 prevPageIdentifier가 제공되었는지 확인
      const prevPageIdentifier = location.state?.prevPageIdentifier;

      console.log('Navigator prevPageIdentifier', prevPageIdentifier);

      // resultHandler를 찾아봄
      const resultHandler =
        typeof prevPageIdentifier === 'string' &&
        navigationStore.identifierAndResultHandlerMap.get(prevPageIdentifier);

      console.log('Navigator resultHandler', resultHandler);

      // 뒤로가기 수행
      navigate(-1);

      // resultHandler가 있으면 실행해줌
      if (resultHandler && destination.state.result) {
        resultHandler(destination.state.result);
      }
    } else if (destination.to !== '') {
      const stateObj = {
        ...destination.state, // mobx에서 풀어줌
      };
      navigate(destination.to, {
        replace: destination.replace,
        state: stateObj,
      });
    }
  }, [destination]);

  return null;
});

export default Navigator;
