import { ValidatorErrorT } from 'types/shared/reqAndRes/CommonResponse';
import interfaceBridge from './interfaceBridge';

export class MyError {
  // 예상되는 대응 케이스에 없는 validation 오류 또는 network 오류를 공통으로 처리
  static handleCatchedError = async (e: any) => {
    let detailErrorMessage = '';
    let hint = '';

    if (e.response) {
      const statusCode = e.response.status;
      if (statusCode >= 400 && statusCode < 500) {
        detailErrorMessage = '잘못된 요청입니다.';
        if (e.response.data && e.response.data.errorObj) {
          // express validator 의 경우
          const errors: ValidatorErrorT[] = e.response.data.errorObj;
          errors.forEach((error) => {
            console.log('ValidatorError : ', error);
            hint = hint + ' ' + error.param;
          });
        }
      } else if (statusCode >= 500 && statusCode < 600) {
        detailErrorMessage = '서버 오류입니다.';
      } else {
        detailErrorMessage = `${statusCode}`;
      }
    } else if (e.message === 'Network Error') {
      detailErrorMessage = '인터넷 상태가 좋지 않습니다.';
    } else {
      detailErrorMessage = '알 수 없는 오류입니다.';
    }

    let errorMessage = `문제가 발생했습니다. ${detailErrorMessage}`;
    if (hint.length > 0) errorMessage += ` (${hint.trim()})`;

    await interfaceBridge.customAlert('실패', errorMessage, '닫기');
  };
}
