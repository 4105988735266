import { ServiceConfig } from 'constants/shared/ServiceConfig';

class ImageHelper {
  static resize(
    image: HTMLImageElement,
    maxLongSide: number,
  ): HTMLImageElement | null {
    if (image.width <= maxLongSide && image.height <= maxLongSide) {
      return image;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return null;

    // 축소할 비율 정하기, 가로세로가 모두 지정값 이하이면 축소하지 않는다.
    const ratio = Math.min(
      1,
      maxLongSide / image.width,
      maxLongSide / image.height,
    );

    // 작은 이미지 크기를 정하고 temp에서 축소 복사
    canvas.width = image.width * ratio;
    canvas.height = image.height * ratio;
    context.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    const resizedImage = new Image();

    resizedImage.src = canvas.toDataURL('image/jpeg', 0.8);

    return resizedImage;
  }

  static cropCenter(
    image: HTMLImageElement,
    maxLongSide: number,
    ratioW: number,
    ratioH: number,
  ) {
    if (ratioW === 0 || ratioH === 0) return null;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return null;

    let sx = 0;
    let sy = 0;
    let cropWidth = 0; // 원본 이미지에서 떼어내어야 하는 크기
    let cropHeight = 0; // 원본 이미지에서 떼어내어야 하는 크기

    if (image.width * ratioH > image.height * ratioW) {
      // 좌우가 잘리는 형태
      cropHeight = image.height;
      cropWidth = (cropHeight * ratioW) / ratioH;
      sx = (image.width - cropWidth) / 2;
    } else {
      // 위아래가 잘리는 형태
      cropWidth = image.width;
      cropHeight = (cropWidth * ratioH) / ratioW;
      sy = (image.height - cropHeight) / 2;
    }

    // 축소할 비율 정하기, 가로세로가 모두 지정값 이하이면 축소하지 않는다.
    const ratio = Math.min(
      1,
      maxLongSide / cropWidth,
      maxLongSide / cropHeight,
    );

    // 크롭된 이미지 크기를 정하고 temp에서 축소 복사
    canvas.width = cropWidth * ratio;
    canvas.height = cropHeight * ratio;
    context.drawImage(
      image,
      sx,
      sy,
      cropWidth,
      cropHeight,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    const croppedImage = new Image();

    croppedImage.src = canvas.toDataURL('image/jpeg', 0.8);

    return croppedImage;
  }

  static async getImageFromFile(file: File): Promise<HTMLImageElement | null> {
    const result = await new Promise<HTMLImageElement | null>(
      (resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          if (!fileReader.result) {
            resolve(null);
            return;
          }

          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.onerror = () => {
            resolve(null);
          };
          img.src = fileReader.result as string; // readAsDataURL - 결과 자체가 base64 string 이라네
        };
        fileReader.onerror = () => {
          resolve(null);
        };
        fileReader.readAsDataURL(file);
      },
    );

    return result;
  }

  // 파일로부터 이미지를 읽어 가로, 세로, 썸네일 데이터를 리턴해줌
  static async getImageInfoFromFile(file): Promise<{
    width: number;
    height: number;
    base64ThumbnailData: string;
  } | null> {
    const image = await this.getImageFromFile(file);
    if (!image) return null;

    const thumbnailImage = await this.resize(
      image,
      ServiceConfig.Post.thumbnailImageMaxLongSide,
    );
    if (!thumbnailImage) return null;

    return {
      width: image.width,
      height: image.height,
      base64ThumbnailData: thumbnailImage.src,
    };
  }
}

export default ImageHelper;
