export const Post = {
  Paragraph: {
    Type: {
      text: 1,
      personalMessage: 2,
      image: 3,
      youtube: 4,
      attachment: 5,
      video: 6,
      dummyImage: 7,
    },
  },
  Type: {
    boardPost: 1,
    notePost: 2,
  },
  TagType: ['class', 'grade', 'homework'],
  Comment: {
    Type: {
      text: 1,
      image: 2,
    },
  },
};
