import { Box, useTheme } from '@mui/material';
import { Rings } from 'react-loader-spinner';

function LoadingScreen() {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
      }}
    >
      <Rings
        height="80"
        width="80"
        color={palette.neutral?.[800]}
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </Box>
  );
}

export default LoadingScreen;
