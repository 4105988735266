export const Common = {
  Gender: {
    undef: 0,
    male: 1,
    female: 2,
  },

  School: {
    Level: {
      elementary: 10,
      middle: 20,
      high: 30,
    },
  },
  Grade: {
    undef: 0,
    age3: 103,
    age4: 104,
    age5: 105,
    age6: 106,
    age7: 107,
    ele1: 201,
    ele2: 202,
    ele3: 203,
    ele4: 204,
    ele5: 205,
    ele6: 206,
    mid1: 301,
    mid2: 302,
    mid3: 303,
    high1: 401,
    high2: 402,
    high3: 403,
    nth: 500,
    etc: 999,
  },
  GradeOrder: [
    'nth',
    'high3',
    'high2',
    'high1',
    'mid3',
    'mid2',
    'mid1',
    'ele6',
    'ele5',
    'ele4',
    'ele3',
    'ele2',
    'ele1',
    'age7',
    'age6',
    'age5',
    'age4',
    'age3',
    'etc',
    'undef',
  ],
};
