export const Message = {
  ChargeMethod: {
    auto: 1,
    manual: 2,
    admin: 3, // event 등
  },
  Content: {
    maxUnicodeByte: 1990,
    maxSmsUnicodeByte: 90,
  },
  SendTiming: {
    now: 1,
    atReservedTime: 2,
  },
  ReceiverNumberType: {
    parentPhone: 1,
    studentPhone: 2,
    altPhone: 3,
    manual: 4,
    none: 99, // admin 등
  },
};
