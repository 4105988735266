export const Permission = {
  // 게시판 읽기 쓰기 권한
  Board: {
    Read: {
      director: 5, // 관리자만
      assistantDirector: 10, // 관리자, 운영자
      assignedTeacherAndStudent: 20, // 배정된 교사,학생 - 일반게시판은 이 개념 없음
      allTeacherAndStudent: 30, // 모든 교사,학생
      everyOne: 40, // 외부생까지
    },
    Write: {
      director: 5, // 관리자만
      assistantDirector: 10, // 관리자, 운영자
      assignedTeacher: 20, // 배정된 교사 - 일반게시판은 이 개념 없음
      allTeacher: 30, // 모든 교사
      everyOne: 40, // 외부생까지
    },
  },
};
