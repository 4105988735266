// 이 소스 수정시 public/assets/js/config 도 같이 수정해야 한다.
// node env 등 환경설정 내용을 구조화 해 내보낸다.

const Config = {
  apiBaseUrl: `${process.env.REACT_APP_API_BASE_URL}`,
  fileServerBaseUrl: `${process.env.REACT_APP_FILE_SERVER_BASE_URL}`,
  chatServerApiBaseUrl: `${process.env.REACT_APP_CHATSERVER_API_PROTOCOL}://${process.env.REACT_APP_CHATSERVER_HOST}:${process.env.REACT_APP_CHATSERVER_API_PORT}`,
  chatServerSocketioUrl: `${process.env.REACT_APP_CHATSERVER_SOCKETIO_PROTOCOL}://${process.env.REACT_APP_CHATSERVER_SOCKETIO_HOST}:${process.env.REACT_APP_CHATSERVER_SOCKETIO_PORT}`,
  chatServerSocketioPath: `${process.env.REACT_APP_CHATSERVER_SOCKETIO_PATH}`,

  postImagePathPrefix: '/postImage',
  smallPostImagePathPrefix: '/postImage/small',
  postVideoPathPrefix: '/postVideo',
  postVideoThumbnailPathPrefix: '/postVideo/thumbnail',
  postAttachmentPathPrefix: '/postAttachment',

  academyBillingInfoUrl: `${process.env.REACT_APP_ACADEMY_BILLING_INFO_URL}`,
};

console.log('process.env', process.env);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).anotherClassConfig = Config;

export default Config;
