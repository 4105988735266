import { makeAutoObservable } from 'mobx';
import Str from 'utils/Str';

class NavigationStore {
  destination: {
    to: string;
    replace: boolean;
    state?: any;
  } = {
    to: '',
    replace: false,
  };

  identifierAndResultHandlerMap: Map<string, (resultObj: any) => void> =
    new Map();

  constructor() {
    makeAutoObservable(this);
  }

  navigate = (to: string, replace = false): void => {
    console.log('NavigationStore navigate');
    this.destination = {
      to,
      replace,
    };
  };

  navWithResultHandler = (
    to: string,
    resultHandler: (resultObj: any) => void,
  ): void => {
    console.log('NavigationStore navWithResultHandler');
    const identifier = Str.randomAlphaNumericStr(10);
    this.identifierAndResultHandlerMap.set(identifier, resultHandler);

    console.log('NavigationStore identifier : ', identifier);

    console.log('NavigationStore resultHandler : ', resultHandler);

    this.destination = {
      to,
      replace: false,
      state: {
        prevPageIdentifier: identifier,
      },
    };
  };

  // result는 이전 페이지에 전달할 객체
  back = (result?: Object): void => {
    console.log('NavigationStore back, result : ', result);

    this.destination = {
      to: '-1',
      replace: false, // to==-1 일때는 사용안됨
      state: {
        result,
      },
    };
  };
}

export default NavigationStore;
