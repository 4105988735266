import React from 'react';
import { Route } from 'react-router-dom';

//
//
//
// user
//

const SignIn = React.lazy(() => import('pages/user/SignIn/SignIn'));

const RouteListUser = (
  <Route path="/user/">
    {/* 공통 화면 시작 */}

    <Route path="signIn" element={<SignIn />} />
  </Route>
);

export default RouteListUser;
