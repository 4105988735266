import React from 'react';
import { Route } from 'react-router-dom';

//
//
//
// user
//

const Test = React.lazy(() => import('pages/etc/Test/Test'));
const FormTest = React.lazy(() => import('pages/etc/FormTest/FormTest'));

const RouteListTest = (
  <Route path="/etc/">
    <Route path="test" element={<Test />} />
    <Route path="formTest" element={<FormTest />} />
  </Route>
);

export default RouteListTest;
