/* eslint-disable @typescript-eslint/no-explicit-any */
// 이 파일은 asset 내의 interface.js 를
// typescript / react 에서 사용하기 좋게 감싸거나
// helper function 을 제공한다.

// base64

import { DeviceInfoKeyT } from 'types/frontend/app';
import { NativeDataKeyT } from 'types/frontend/shared';
import { TokenObjT } from 'types/shared/core/Token';
import { rootStore } from 'stores/root/RootStore';
import generalInterface from 'pcBrowserInterface/generalInterface';

console.log('interfaceBridge loaded');

//
// main
function requestNativeFeature(
  obj: any,
  resultHandler?: any,
  shouldReturn?: boolean,
) {
  return generalInterface.requestNativeFeature(
    obj,
    resultHandler,
    shouldReturn,
  );
}

//
//
// navigation
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function back(result?: any) {
  console.log('navigation back');
  rootStore.navigationStore.back(result);
}

// pc용 nav
function nav(to: string, replace = false) {
  rootStore.navigationStore.navigate(to, replace);
}

// pc에서 다음페이지로 갔다가 돌아오면서 핸들러가 필요할 때
function navWithResultHandler(
  to: string,
  resultHandler: (resultObj: any) => void,
) {
  rootStore.navigationStore.navWithResultHandler(to, resultHandler);
}

//
//
// ui snippets
async function customAlert(
  title: string,
  message: string,
  closeBtn: string,
): Promise<void> {
  return await rootStore.alertStore.customAlert(title, message, closeBtn);
}

async function customConfirm(
  title: string,
  message: string,
  confirmBtn: string,
  cancelBtn: string,
  isDestructive?: boolean,
): Promise<boolean> {
  return await rootStore.alertStore.customConfirm(
    title,
    message,
    confirmBtn,
    cancelBtn,
    isDestructive,
  );
}

async function multipleChoice(
  title: string,
  message: string,
  actionBtns: { title: string; value: string }[],
  cancelBtn: string,
): Promise<string | undefined> {
  return await rootStore.alertStore.multipleChoice(
    title,
    message,
    actionBtns,
    cancelBtn,
  );
}

function setNativeEventHandler(
  handler: (eventType: string, encodedEvent: string) => void,
) {
  (window as any).nativeEventHandler = handler;
}

//
//
// token
// null 을 주면 삭제된다.
function saveToken(userId: number, tokenObj: TokenObjT | null): void {
  interfaceBridge.requestNativeFeature(
    {
      action: 'saveToken',
      userId,
      tokenObj,
    },
    null,
    true,
  );
}

function loadToken(userId: number): TokenObjT | null {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'loadToken',
      userId,
    },
    null,
    true,
  );

  if (!result) return null;

  return JSON.parse(result) as TokenObjT | null;
}

function setData(key: NativeDataKeyT, dataStr: string): void {
  interfaceBridge.requestNativeFeature(
    {
      action: 'setData',
      key,
      valueStr: dataStr,
    },
    null,
    true,
  );
  // console.log('setData', dataStr);
}

function getData(key: NativeDataKeyT): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getData',
      key,
    },
    null,
    true,
  );
  // console.log('getData', result);
  return result as string;
}

function deviceInfo(key: DeviceInfoKeyT): string {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'deviceInfo',
      key,
    },
    null,
    true,
  );
  // console.log('deviceInfo', result);
  return result as string;
}

function getUserIds(): number[] {
  const result = interfaceBridge.requestNativeFeature(
    {
      action: 'getUserIds',
    },
    null,
    true, // should return
  ) as string;

  return JSON.parse(result) as number[];
}

function uploadPostImage(
  academyId: number,
  postIdentifier: string,
  maxCount: number,
) {
  // console.log('interfaceBridge - uploadPostImage');
  interfaceBridge.requestNativeFeature({
    action: 'uploadPostImage',
    academyId,
    postIdentifier,
    maxCount,
  });
}

function uploadPostAttachment(
  academyId: number,
  postIdentifier: string,
  maxCount: number,
) {
  interfaceBridge.requestNativeFeature({
    action: 'uploadPostAttachment',
    academyId,
    postIdentifier,
    maxCount,
  });
}

function uploadTempImage(
  academyId: number,
  type: 'studentProfile' | 'userProfile' | 'bizRegCert' | 'repSignature',
  maxWidth: number,
  ratioW: number, // 0 이면 비율 제한 없음 - crop 창 뜨지 않음
  ratioH: number,
  resultHandler: (resultObj: any) => void,
) {
  interfaceBridge.requestNativeFeature(
    {
      action: 'uploadTempImage',
      academyId,
      type,
      maxWidth,
      ratioW,
      ratioH,
    },
    resultHandler,
    false,
  );
}

function downloadFile(
  url: string,
  fileName: string,
  type: 'image' | 'video' | 'etc',
) {
  interfaceBridge.requestNativeFeature({
    action: 'downloadFile',
    url,
    fileName,
    type,
  });
}

// 로그아웃 역할도 수행
function goToSignIn() {
  // 기존 사용자 토큰 없애기
  const data = interfaceBridge.getData('CURRENT_USER_ID');
  const currentUserId = parseInt(data);
  console.log('goToSignIn currentUserId', currentUserId);
  if (currentUserId && currentUserId > 0) {
    interfaceBridge.saveToken(currentUserId, null);
  }

  console.log('nav 전');
  self.location.href = '/user/signIn';
}

const interfaceBridge = {
  requestNativeFeature,

  setNativeEventHandler,
  back,
  nav,
  navWithResultHandler,
  customAlert,
  customConfirm,
  multipleChoice,
  saveToken,
  loadToken,
  getUserIds,
  getData,
  setData,
  deviceInfo,

  // pc용 추가
  goToSignIn,

  // upload/download용
  uploadPostImage,
  uploadPostAttachment,
  uploadTempImage,
  downloadFile,
};

export default interfaceBridge;

console.log('interfaceBridge loaded');
