/* eslint-disable @typescript-eslint/ban-types */
import React, { createContext, PropsWithChildren } from 'react';

import { RootStoreT } from 'types/frontend/RootStore';
import { rootStore } from 'stores/root/RootStore';

export const RootContext = createContext<RootStoreT>(
  {} as RootStoreT, // 물음표를 없애기 위해
);

type RootProviderTypes = PropsWithChildren<{}>;

console.log('updateVersion 정의');

export const RootProvider = ({ children }: RootProviderTypes): JSX.Element => {
  return (
    <RootContext.Provider value={rootStore}>{children}</RootContext.Provider>
  );
};
