const downloadFile = (messageObj, sender) => {
  if (messageObj.action !== 'downloadFile') {
    return { handled: false };
  }

  const url = messageObj.url;
  const fileName = messageObj.fileName;

  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    })
    .catch((err) => {
      console.error(err);
    });

  return {
    handled: true,
  };
};

export { downloadFile };
