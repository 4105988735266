import { Theme, useMediaQuery } from '@mui/material';
import React, {
  createContext,
  useContext,
  useState,
  PropsWithChildren,
  useEffect,
} from 'react';

type LayoutContextTypeT = {
  isWideMode: boolean;
  setIsWideMode: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSidebarOpen: () => void;
};

const LayoutContext = createContext<LayoutContextTypeT | undefined>(undefined);

export const useLayout = (): LayoutContextTypeT => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayout must be used within a LayoutProvider');
  }
  return context;
};

export function LayoutProvider({ children }: PropsWithChildren<{}>) {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  console.log('lgUp', lgUp);

  // 화면이 클 때는 기본적으로 사이드바를 열어둠
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(lgUp);

  // 화면이 클 때 넓은 화면을 컨텐츠를 위해 다 쓸 것인가. (960+280 = 1240 모두 사용)
  const [isWideMode, setIsWideMode] = useState<boolean>(false);

  useEffect(() => {
    console.log('layoutcontext useeffect fired');
    if (lgUp) {
      setIsSidebarOpen(true);
      setIsWideMode(false);
    } else {
      setIsSidebarOpen(false);
      setIsWideMode(false);
    }
  }, [lgUp]);

  const toggleSidebarOpen = () => {
    console.log('toggle sidebar', isSidebarOpen);

    setIsSidebarOpen((prev) => !prev);
    if (lgUp) {
      setIsWideMode((prev) => !prev);
    }
  };
  return (
    <LayoutContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
        toggleSidebarOpen,
        isWideMode,
        setIsWideMode,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}
