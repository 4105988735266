import BaseStore from 'stores/BaseStore';
import { makeAutoObservable } from 'mobx';
import { RootStoreT } from 'types/frontend/RootStore';
import ClsDataStore from 'stores/shared/ClsDataStore';
import TeacherListDataStore from 'stores/TeacherListDataStore';
import StudentListDataStore from 'stores/StudentListDataStore';
import { debounce } from 'debounce';
import { StudentT } from 'types/shared/core/Student';
import { TeacherT } from 'types/shared/core/Teacher';
import { ClsT } from 'types/shared/core/Cls';
import interfaceBridge from 'utils/interfaceBridge';

export type NavbarSearchResultT = {
  category: 'student' | 'teacher' | 'cls';
  item: StudentT | TeacherT | ClsT;
  foundInProperty: string;
  keywordContext: string;
};

function extractSubString(a: string, b: string, n: number): string {
  // Check if string a includes string b
  if (a.includes(b)) {
    console.log('a', a);
    console.log('b', b);

    // Find the start index of string b in string a
    const startIndex = a.indexOf(b);

    // Calculate the start and end positions for the new substring
    let start = Math.max(0, startIndex - n);
    let end = Math.min(a.length, startIndex + b.length + n);

    console.log('start', start);
    console.log('end', end);

    // Determine if ellipses should be added
    const addStartEllipsis = start > 0;
    const addEndEllipsis = end < a.length;

    // Extract the new substring
    let result = a.substring(start, end);

    // Add ellipses if needed
    if (addStartEllipsis) {
      result = '...' + result;
    }
    if (addEndEllipsis) {
      result = result + '...';
    }

    return result;
  } else {
    // Return an empty string or a message if b is not found in a
    return '';
  }
}

export default class NavbarSearchStore implements BaseStore {
  clsDataStore: ClsDataStore;
  teacherListDataStore: TeacherListDataStore;
  studentListDataStore: StudentListDataStore;

  searchKeyword = '';

  results: NavbarSearchResultT[] = [];

  selectedResultIndex: number | null = null;

  constructor(
    studentListDataStore: StudentListDataStore,
    teacherListDataStore: TeacherListDataStore,
    clsDataStore: ClsDataStore,
  ) {
    makeAutoObservable(this);

    this.clsDataStore = clsDataStore;
    this.teacherListDataStore = teacherListDataStore;
    this.studentListDataStore = studentListDataStore;
  }

  init = () => {
    console.log('init');
  };

  handleFocus = () => {
    if (!this.teacherListDataStore.isInited) this.teacherListDataStore.init();
    console.log('handleFocus');
    this.searchItems();
  };

  setSearchKeyword = (keyword: string) => {
    this.searchKeyword = keyword;
    this.searchItemsLazily();
  };

  searchItemsForDebounce = () => {
    this.searchItems();
  };

  searchItemsLazily = debounce(this.searchItemsForDebounce, 300);

  searchItems = () => {
    this.selectedResultIndex = null;
    this.results = [];

    if (!this.searchKeyword) return;
    if (this.searchKeyword.length < 2) return;

    const n = 5;

    const studentSearchProperties = [
      ['name', '이름'],
      ['nickname', '닉네임'],
      ['schoolName', '학교'],
      ['parentPhone', '학부모폰'],
      ['studentPhone', '학생폰'],
      ['altPhone', '추가폰'],
      ['birthday', '생일'],
      ['altPhone', '추가폰'],
      ['simpleMemo', '메모'],
    ];

    for (const student of this.studentListDataStore.studentList) {
      for (const [propertyKey, propertyName] of studentSearchProperties) {
        if (
          student[propertyKey] &&
          String(student[propertyKey])
            .toLowerCase()
            .includes(this.searchKeyword.toLocaleLowerCase())
        ) {
          this.results.push({
            category: 'student',
            item: student,
            foundInProperty: propertyName,
            keywordContext:
              propertyKey === 'simpleMemo'
                ? extractSubString(
                    String(student[propertyKey]),
                    this.searchKeyword,
                    n,
                  )
                : student[propertyKey] || '',
          });
        }
      }
    }

    const teacherSearchProperties = [
      ['name', '이름'],
      ['nickname', '닉네임'],
      ['phone', '전화번호'],
    ];

    for (const teacher of this.teacherListDataStore.teacherItemList) {
      for (const [propertyKey, propertyName] of teacherSearchProperties) {
        if (
          teacher[propertyKey] &&
          String(teacher[propertyKey]).includes(this.searchKeyword)
        ) {
          this.results.push({
            category: 'teacher',
            item: teacher,
            foundInProperty: propertyName,
            keywordContext: teacher[propertyKey] || '',
          });
        }
      }
    }

    const clsSearchProperties = [['name', '이름']];

    for (const cls of this.clsDataStore.clsItemList) {
      for (const [propertyKey, propertyName] of clsSearchProperties) {
        if (
          cls[propertyKey] &&
          String(cls[propertyKey]).includes(this.searchKeyword)
        ) {
          this.results.push({
            category: 'cls',
            item: cls,
            foundInProperty: propertyName,
            keywordContext: cls[propertyKey] || '',
          });
        }
      }
    }
  };

  setSelectedItemIndex = (i: number) => {
    this.selectedResultIndex = i;
  };

  moveSelectedItemIndex = (direction: 'up' | 'down') => {
    console.log('moveSelectedItemIndex executed', direction);

    if (this.selectedResultIndex === null) {
      this.selectedResultIndex = 0;
      return;
    }

    if (direction === 'up' && this.selectedResultIndex > 0) {
      this.selectedResultIndex--;
    }

    if (
      direction === 'down' &&
      this.selectedResultIndex < this.results.length - 1
    ) {
      this.selectedResultIndex++;
    }
  };

  moveSelectedItemIndexForDebounce = (direction: 'up' | 'down') => {
    this.moveSelectedItemIndex(direction);
  };

  moveSelectedItemIndexLazily = debounce(
    this.moveSelectedItemIndexForDebounce,
    50,
  );

  handleEnterKey = (withModifierKey: boolean) => {
    if (this.selectedResultIndex === null) return;
    const result = this.results[this.selectedResultIndex];
    if (!result) return;

    console.log('result', result);

    let subpath = '';
    switch (result.category) {
      case 'student':
        subpath = `/teacherSide/management/student/detail/${
          (result.item as StudentT).id
        }`;
        break;
      case 'teacher':
        subpath = `/teacherSide/management/teacher/detail/${
          (result.item as TeacherT).teacherId
        }`;
        break;
      case 'cls':
        subpath = `/teacherSide/management/cls/detail/${
          (result.item as ClsT).id
        }`;
    }

    if (withModifierKey) {
      const origin = window.location.origin;
      const url = `${origin}${subpath}`;
      window.open(url, '_blank');
    } else {
      interfaceBridge.nav(subpath);
      this.results = [];
    }
  };

  handleMouseOver = (index: number) => {
    this.selectedResultIndex = index;
  };

  handleResultClick = (index: number, withModifierKey: boolean) => {
    this.handleEnterKey(withModifierKey);
  };
}
