export const ServiceConfig = {
  Post: {
    maxTitleLength: 50,

    paragraphMaxCount: 15,

    imageMaxCount: 10,
    imageMaxLongSide: 2000,
    thumbnailImageMaxLongSide: 100, // 업로드시 화면에 표시하는 블루어 이미지 크기

    videoMaxCount: 1,

    attachmentMaxCount: 2,
    attachmentMaxSizeByte: 10 * 1024 * 1024,

    pmMaxStudentForEdit: 20, // 개인별 메시지에서 개별 수정이 가능한 최대 전체 인원

    maxTextParagraphLength: 2000,
    maxCommentLength: 300,
  },

  Common: {
    maxNameLength: 12,
    maxPhoneLength: 11,
    minTeacherPasswordLen: 8,
    minUserPasswordLen: 8,
    maxPasswordLength: 12,
    maxClassNameLength: 12,
    maxSchoolNameLength: 20,
    maxAddressLength: 100,
    studentsInMultipleForm: 10, // 학생 여러명 등록의 개수
    maxNumberOfStudentsInAcademy: 500, // 재원생 + 외부생
    maxGeneralContentLength: 300, // 한줄메모, 상담기록
    maxTeacherIdLength: 20,
  },

  Attendance: {
    maxShortAcademyNameLength: 14,
    maxAttendanceCommentLength: 100,
    attPasswordLength: 12,
    maxLessonCount: 60,
  },

  Bill: {
    maxCorpNicknameLength: 5,
    presetNewItemCountPerDialog: 5,
    maxBillTitleLength: 50,
    maxBillMemoLength: 50,
    maxBillPresetTitleLength: 60,
    maxBillItemTitleLength: 65,
    minCbNumberLength: 10,
    maxCbNumberLength: 19,
    maxBillDueDay: 28, // 개인별 특정일 청구의 최대날짜

    maxRepSignatureImageSize: 300,
  },

  User: {
    maxProfileImageSize: 300,
  },

  Service: {
    ServiceName: '어나더클래스',
    GeneralDirectoryNumber: '0317018285',
    GeneralInquiryEmail: 'master@anotherclass.co.kr',
    AppStoreShortLink: 'bit.ly/anot',
    NoticeSenderEmail: 'noreply@anotherclass.co.kr',
  },

  StudentXlsx: {
    version: 4,
    titleRow: 9,
    columns: [
      '이름',
      '학부모 연락처',
      '학생 연락처',
      '추가 연락처',
      '생년월일',
      '성별',
      '닉네임',
      '학교',
      '학년',
      '들어온 날',
      '한줄 메모',
    ],
    maxFileSizeMB: 0.5,
  },

  Chat: {
    maxContentLength: 300,
  },

  Homework: {
    maxAdditionalResourcesCount: 3,
    maxAdditionalResourceNameLength: 10,
    maxAdditionalResourceValueLength: 200,
    maxTitleLength: 50,
    maxDescriptionLength: 1000,

    maxTagListLength: 100, // 콤마 포함
    maxPresetCountPerRequest: 500,
    maxHomeworkPresetPerSource: 10000,
  },

  HomeworkXlsx: {
    version: 1,
    titleRow: 8,
    columns: [
      '제목',
      '내용',
      '참고자료1 제목',
      '참고자료1 내용',
      '참고자료2 제목',
      '참고자료2 내용',
      '참고자료3 제목',
      '참고자료3 내용',
      '태그',
    ],
    maxFileSizeMB: 1,
  },
};
