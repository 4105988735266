import UserStore from 'stores/root/UserStore';

import ClsDataStore from 'stores/shared/ClsDataStore';

import PageStore from './PageStore';
import { RootStoreT } from 'types/frontend/RootStore';
import NavigationStore from './NavigationStore';
import StudentListDataStore from 'stores/StudentListDataStore';
import AlertStore from './AlertStore';
import { reaction } from 'mobx';
import TeacherListDataStore from 'stores/TeacherListDataStore';
import NavbarSearchStore from './NavbarSearchStore';

export const updateVersion = (
  version: 'studentList' | 'teacherList' | 'clsList' | 'myInfo',
) => {
  console.log('updateVersion', version);
  switch (version) {
    case 'studentList':
      rootStore.studentListDataStore.setVersion(new Date().toString());
      break;
    case 'teacherList':
      rootStore.teacherListDataStore.setVersion(new Date().toString());
      break;

    case 'clsList':
      rootStore.clsDataStore.setVersion(new Date().toString());
      break;
    case 'myInfo':
      rootStore.userStore.setVersion(new Date().toString());
  }
};

export const getActiveAcademyId = () => {
  return rootStore.pageStore.activeAcademyId;
};

const userStore = new UserStore();
const clsDataStore = new ClsDataStore();
const teacherListDataStore = new TeacherListDataStore(clsDataStore);
const studentListDataStore = new StudentListDataStore(clsDataStore);
const pageStore = new PageStore();
const navigationStore = new NavigationStore();
const alertStore = new AlertStore();
const navbarSearchStore = new NavbarSearchStore(
  studentListDataStore,
  teacherListDataStore,
  clsDataStore,
);

const rootStore: RootStoreT = {
  userStore,
  clsDataStore,
  teacherListDataStore,
  studentListDataStore,
  pageStore,
  navigationStore,
  alertStore,
  navbarSearchStore,

  stores: {},
};

// clsDataStore와 studentListDataStore는
// 로그인한 상태에서만 불러온다.
console.log('rootStore reaction');
console.log('userStore.myInfo', userStore.myInfoTimestamp);
reaction(
  () => userStore.myInfoTimestamp,
  () => {
    console.log('userStore.myInfo reaction');
    if (userStore.myInfo) {
      console.log('userStore.myInfo reaction valid');
      rootStore.clsDataStore.init('rootStore');
      rootStore.studentListDataStore.init();
    }
  },
);

export { rootStore };

// app 에서는 clsDataStore와 studentListDataStore는 항상 init한다.
